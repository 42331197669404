.containerBG {
    display: block;
    background: rgb(8,174,234);
    background: linear-gradient(45deg, rgba(8,174,234,1) 35%, rgba(42,245,152,1) 100%);
    padding: 0;
    margin: 0;
    width: 100%;
  }

.imgLogin {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgLogin img{
  width: 50%;
  align-items: center;
  

}

.titleContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  color: white;
  background: #3f51b5;
}
  